/************************************************************
** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
** 否则页面将无法正常显示                                  **
************************************************************/

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', 'Microsoft Yahei', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  background-color: #0c0e16
}

.bounceIn {
  animation-duration: 0.6s;
  animation-name: bounceIn;
}

.slideInDown {
  animation-duration: 1s;
  animation-name: slideInDown;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -1000%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }

  50% {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  80% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.adm-auto-center-content {
  text-align: center;
}

.adm-infinite-scroll {
  padding: 0 !important;
  padding-top: 0.5px !important;
}

* {
  box-sizing: border-box !important;
  flex-shrink: 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

@font-face {
  font-family: "Jidu ROBOFont";
  src: url("./assets/fonts/JiduROBOFont-Regular.otf");
}

@font-face {
  font-family: "Jidu Sans";
  src: url("./assets/fonts/JiduSans-Regular.otf");
}

@font-face {
  font-family: "Jidu Mono Pro";
  src: url("./assets/fonts/JiduMonoPro-Regular.otf");
}

@font-face {
  font-family: "Jidu Mono";
  src: url("./assets/fonts/JiduMono-Regular.otf");
}

$vw_base: 375;
@function vw($px) {
  @return ($px / $vw_base) * 100vw;
}