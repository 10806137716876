@import "../../App.scss";

.page {
  position: absolute;
  padding: vw(37) 0 8.67vw vw(31);
  background-color: #000005;
  background-image: url('./assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
  width: 100vw;
  overflow-x: hidden;
  height: calc(763 / 375 * 100vw);
  min-height: 100vh;
  .group_1 {
    position: relative;
    z-index: 1;
    .image {
      margin-top: 2.13vw;
      width: 20.53vw;
      height: 5.33vw;
    }
    .text-wrapper {
      justify-content: center;
      position: relative;
      border-radius: 71.73vw 0 0 71.73vw;
      width: vw(53);
      height: vw(28);
      .text_1 {
        color: #dbceb5;
        font-size: 3.2vw;
      }
    }
    .text-wrapper::before {
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #dbceb5;
      border-radius: 71.73vw 0 0 71.73vw;
      content: "";
      transform: scale(0.5);
      -webkit-transform: scale(0.5);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
      width: vw(106);
      height: vw(56);
    }
  }
  .image_1 {
    position: relative;
    z-index: 1;
    width: vw(252);
    height: vw(31);
    margin-top: vw(21.32);
    background-image: url("./assets/1171274798.png");
    background-size: 100% 100%;
  }
  .group_2 {
    position: relative;
    z-index: 1;
    margin-top: vw(14);
    height: vw(32);
    .text_2 {
      font-weight: 700;
      font-size: vw(30);
      line-height: vw(32);
      letter-spacing: vw(2);
      color: #DBCEB5;
    }
  }
  .text_4 {
    margin-top: 2.67vw;
    align-self: flex-start;
    color: #ffffff;
    font-size: vw(30);
    line-height: vw(32);
    letter-spacing: vw(2);
    text-transform: uppercase;
  }
  .loading {
    position: absolute;
    top: calc(104 / 375 * 100vh);
    left: calc(138 / 375 * 100vw);
  }
  .group_3 {
    position: relative;
    margin-top: calc(393 / 739 * 100vh);
    z-index: 1;
    .group_4 {
      .image_2 {
        opacity: 0.2;
        width: 82.67vw;
        height: 82.67vw;
        position: absolute;
        left: 0.27vw;
        top: 8vw;
      }
      .image_4 {
        width: 33.87vw;
        height: 4.53vw;
        position: absolute;
        right: 7.73vw;
        bottom: 20.27vw;
      }
      .text-wrapper_3 {
        padding: 4.8vw 0 4.53vw;
        position: relative;
        left: 0;
        top: 103.47vw;
      }
      .image_5 {
        width: 79.73vw;
        height: 105.6vw;
        position: absolute;
        right: 0;
        top: 0;
      }
      .image_6 {
        width: 84vw;
        height: 84vw;
        position: absolute;
        left: 1.33vw;
        top: 5.87vw;
      }
    }
  }
  .text {
    position: relative;
    z-index: 1;
    text-transform: uppercase;
  }
  .group_5 {
    position: relative;
    z-index: 1;
    margin-top: vw(24);
    padding: 0 1.4vw 0 0;
    .text_6 {
      margin-right: 6.8vw;
      align-self: flex-end;
      color: #dbceb5;
      font-size: 2.13vw;
      font-family: Jidu Mono Pro;
      line-height: 3.87vw;
    }
    .text-wrapper_1 {
      margin-top: 2.8vw;
      padding: 1.07vw 0 0.8vw;
      width: 3.47vw;
      height: 3.47vw;
      border: solid 0.13vw #dbceb5cc;
      .text_7 {
        color: #e0d6c2;
        font-size: 1.87vw;
        font-family: Jidu Mono;
        line-height: 1.33vw;
      }
    }
    .group_7 {
      margin-right: 6.8vw;
      width: 19.07vw;
      .text_8 {
        align-self: flex-end;
        color: #dbceb5;
        font-size: 2.13vw;
        font-family: Jidu Mono Pro;
        line-height: 3.87vw;
      }
      .text_9 {
        align-self: flex-end;
        color: #dbceb5;
        font-size: 2.13vw;
        font-family: Jidu Mono Pro;
        line-height: 3.87vw;
      }
    }
  }
  .tips-line, .tips-content {
    position: fixed;
    z-index: 99;
    color: #ffffff;
    pointer-events: none;
  }
  .tips-line {
    font-size: vw(14);
    line-height: vw(20);
    color: rgba(255, 255, 255, 0.8);
    .line {
      position: relative;
      left: vw(-4);
    }
  }
  .tips-content {
    width: vw(143);
    bottom: vw(23);
    margin-left: calc(116 / 375 * 100vw - 32 / 375 * 100vw);
    .tips-toast {
      height: vw(33);
      background-image: url("./assets/1000003697.png");
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tips-number {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: vw(33);
    }
  }
  .game {
    z-index: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
  .bottom-light {
    position: absolute;
    bottom: 0;
    left: 0;
    background: url("../../assets/common/bottom-light.png") no-repeat center;
    background-size: 100% 100%;
    mix-blend-mode: lighten;
    width: 100vw;
    height: 97.33vw;
  }
  .block {
    display: block;
  }
  .hide {
    display: none;
  }
}