.double-check-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  .modal-content-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: inline-block;
    overflow-y: scroll;

    .top-tips-text {
      position: relative;
      margin-top: 65vw;
      width: 100vw;
      font-weight: 400;
      font-size: 3.73vw;
      line-height: 5.33vw;
      color: #FFFFFF;
      text-align: center;
      display: inline-block;
      white-space:pre-wrap;
    }

    .buttons-group {
      position: relative;
      width: 84vw;
      margin: 11vw auto 50vw auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .button-cancel {
        position: relative;
        width: 40.53vw;
        height: 12.8vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 3.73vw;
        line-height: 5.33vw;
        color: #FFFFFF;
        border: 1px solid #7D33FF;
      }

      .button-confirm {
        position: relative;
        width: 40.53vw;
        height: 12.8vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 3.73vw;
        line-height: 5.33vw;
        color: #FFFFFF;
        background: #7D33FF;
      }
    }
  }
}