.music-icon {
  position: absolute;
  right: 4.27vw;
  top: 23vw;
  background: url("../../assets/common/music-icon.png") no-repeat center;
  background-size: 100% 100%;
  width: 4.27vw;
  height: 4.27vw;
  z-index: 80;
}

.muted-icon {
  background: url("../../assets/common/muted-icon.png") no-repeat center;
  background-size: 100% 100%;
  width: 4.27vw;
  height: 4.27vw;
}