@import "../../App.scss";

.confirm-swap-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);

  .content-container {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;

    .top-tips{
      display: flex;
      flex-direction: column;
      align-items: center;
      .points {
        display: flex;
        align-items: center;
        .icon {
          width: 34px;
          height: 34px;
        }
        .number {
          font-family: 'Jidu Mono Pro';
          font-size: vw(34);
          line-height: 52px;
          text-transform: uppercase;
          color: #DBCEB5;
        }
      }
      .text {
        position: relative;
        width: 73.33vw;
        font-size: 3.73vw;
        line-height: vw(20);
        color: #FFFFFF;
        display: inline-block;
        white-space:pre-wrap;
        text-align: center;
      }
    }
  
    .buttons-group {
      position: relative;
      width: 84vw;
      margin-top: 10.67vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
  
      .button-cancel {
        position: relative;
        width: 40.53vw;
        height: vw(48);
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: vw(14);
        line-height: vw(20);
        color: #FFFFFF;
        border: 1px solid #7D33FF;
      }
  
      .button-confirm {
        position: relative;
        width: 40.53vw;
        height: vw(48);
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 3.73vw;
        line-height: 5.33vw;
        color: #FFFFFF;
        background: #7D33FF;
      }
    }
  }
  
}