.box {
  margin: calc(76.7 / 375 * 100vw) auto;
  width: 84.3vw;
  overflow-y: scroll;
  overflow-x: hidden;
  .image {
    align-self: flex-start;
    width: 6.13vw;
    height: 6.4vw;
  }
  .group {
    padding-left: 0.53vw;
    .text {
      width: calc(310 / 375 * 100vw);
      height: calc(30 / 375 * 100vw);
      align-self: flex-start;
      background-image: url("./assets/1171274800.png");
      background-size: 100% 100%;
    }
    .section_1 {
      margin: 3.47vw 0.8vw 0 0.27vw;
      background-image: url('./assets/44ebc336af88fc27018d0e70da738a80.png');
      background-position: 0% 0%;
      background-size: 82.67vw 82.67vw;
      background-repeat: no-repeat;
      .image_1 {
        width: 4.53vw;
        height: 4.8vw;
      }
      .image_3 {
        width: 4.8vw;
        height: 4.53vw;
      }
      .text_2 {
        margin-left: 2.67vw;
        margin-top: 0.67vw;
        align-self: flex-start;
        color: #dbceb5;
        font-size: 1.87vw;
        font-family: Jidu Mono Pro;
        line-height: 3.2vw;
      }
      .group_3 {
        padding: 0 2.53vw;
        .text_3 {
          color: #dbceb5;
          font-size: 1.87vw;
          font-family: Jidu Mono Pro;
          line-height: 3.2vw;
        }
        .text_4 {
          margin-left: 3.07vw;
          color: #dbceb5;
          font-size: 1.87vw;
          font-family: Jidu Mono Pro;
          line-height: 3.2vw;
        }
        .section_2 {
          margin: 0.67vw 0 1.2vw 1.2vw;
          background-color: #dbceb5;
          width: 0.53vw;
          height: 1.33vw;
        }
      }
      .group_4 {
        padding: 3.87vw 0 59.07vw;
        position: relative;
        .text_5 {
          height: 3.2vw;
        }
        .group_5 {
          width: 69.73vw;
          position: absolute;
          left: 2.53vw;
          top: 0;
          .image_4 {
            position: relative;
            filter: drop-shadow(0px 0.8vw 1.07vw #080027);
            width: 63.2vw;
            height: 66.13vw;
            z-index: 10;
          }
          .text_6 {
            color: #dbceb5;
            font-size: 1.87vw;
            font-family: Jidu Mono Pro;
            line-height: 3.2vw;
            text-transform: uppercase;
            position: absolute;
            left: 0;
            top: 0.4vw;
          }
        }
      }
      .image_5 {
        margin-top: 0.4vw;
        width: 4.8vw;
        height: 4.4vw;
      }
    }
    .divider {
      margin-top: 2.93vw;
      .group_7 {
        margin-right: 1.45vw;
        width: 26vw;
        .view {
          flex-shrink: 0;
        }
        .text_7 {
          margin-left: 2.27vw;
          color: #fbebd0;
          font-size: 1.87vw;
          font-family: Jidu Mono;
          line-height: 1.33vw;
        }
      }
      .section_3 {
        margin: 0.53vw 0 0.27vw;
        background-color: #fbebd0;
        width: 0.53vw;
        height: 0.53vw;
      }
    }
    .text_1 {
      text-transform: uppercase;
    }
  }
  .group_8 {
    margin-right: 0.4vw;
    padding: 6.93vw 0;
    .section_4 {
      background-color: #c5baa4;
      width: 1.6vw;
      height: 1.6vw;
    }
    .view_3 {
      margin-right: 0.4vw;
    }
    .text_8 {
      margin-top: 4.53vw;
      text-align: center;
      color: rgba(255, 255, 255, 0.8);;
      font-size: 3.73vw;
      line-height: 6.93vw;
    }
  }
  .button {
    margin-top: 4.53vw;
    padding: 4.8vw 0 4.53vw;
    background-color: #7d33ff;
    .text_9 {
      color: #ffffff;
      font-size: 3.73vw;
      line-height: 3.47vw;
    }
  }
}

.loading {
  background-color: #000005;
  background-image: url('./assets/cd643df72a61e1a8c16d897b0e2df2bc.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100vw;
  overflow: hidden;
  height: 100vh;
  .image {
    filter: drop-shadow(0px 0.8vw 1.07vw #080027);
    width: 100vw;
    height: calc(1500 / 600 * 100vw);
  }
  .text {
    position: absolute;
    margin-top: calc(478 / 375 * 100vw);
    color: #ffffffe6;
    font-size: 4.27vw;
    line-height: 4vw;
  }
  .bottom-light {
    position: absolute;
    bottom: -1px;
    background: url("../../../../assets/common/bottom-light.png") no-repeat center;
    background-size: 100% 100%;
    mix-blend-mode: lighten;
    width: 100vw;
    height: 97.33vw;
  }
}