.redeem-success-page {
  position: relative;
  width: 100vw;
  min-height:100vh;
  display: flex;
  flex-direction: column;
  background: url("../../assets/redeemSuccess/redeem-success-bg.png") no-repeat center;
  background-size: cover;

  .robbit-title {
    position: relative;
    margin-top: 12.5vw;
    margin-left: 8.3vw;
    font-weight: 400;
    font-size: 10.67vw;
    line-height: 10.67vw;
    font-family: 'Jidu ROBOFont';
    -webkit-text-stroke: 0.5px #DBCEB5;
    -webkit-text-fill-color: transparent;
  }

  .main-title {
    position: relative;
    margin-top: 1.2vw;
    width: 100vw;
    text-align: center;
    font-weight: 700;
    font-size: 4.7vw;
    line-height: 4.8vw;
    color: #DBCEB5;
  }

  .nft-back-container {
    position: relative;
    margin: 5vw auto 0 auto;
    background: url("../../assets/redeemSuccess/nft-back-container.png") no-repeat center;
    background-size: 100% 100%;
    height: 93.6vw;
    width: 82.67vw;
    display: flex;
    flex-direction: column;
    align-items:center;

    .nft-title-container {
      position: relative;
      margin-top: 5.33vw;
      display: flex;
      flex-direction: row;
      align-items: center;

      .level-text {
        position: relative;
        margin-right: 1.6vw;
        font-weight: 400;
        font-size: 4.8vw;
        line-height: 5.6vw;
        color: #DBCEB5;
        font-family: "Jidu Mono Pro";
      }

      .name-text {
        position: relative;
        font-weight: 400;
        font-size: 4.27vw;
        line-height: 4.27vw;
        color: #DBCEB5;
      }
    }

    .nft-image {
      position: relative;
      margin-top: 4.53vw;
      width: 74.67vw;
      height: 74.67vw;
    }

    .digital-collection-text {
      position: absolute;
      right: 0;
      bottom: -4vw;
      background: url("../../assets/redeemSuccess/digital-collection-text.png") no-repeat center;
      background-size: 100% 100%;
      height: 1.35vw;
      width: 29vw;
    }
  }

  .equity-desc {
    position: relative;
    max-width: 78vw;
    margin: 9vw auto 8vw auto;
    font-weight: 400;
    font-size: 3.73vw;
    line-height: 5.33vw;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    display: inline-block;
    white-space:pre-wrap;
  }

  .success-page-button-collect {
    position: relative;
    margin: 0 auto;
    width: 84vw;
    height:12.8vw;
    background: #7D33FF;
    display:flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 3.73vw;
    line-height: 5.33vw;
    color: #FFFFFF;
    z-index: 20;
  }

  .disabled-bg {
    background: rgba(120, 120, 120, 0.5);
  }

  .success-page-button-check-or-poster {
    position: relative;
    margin: 0 auto 0 auto;
    width: 84vw;
    height:12.8vw;
    background: #7D33FF;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 3.73vw;
    line-height: 5.33vw;
    color: #FFFFFF;
    z-index: 20;
  }

  .loading-status {
    border:none;
    background: rgba(120, 120, 120, 0.5);
    font-weight: 400;
    font-size: 3.73vw;
    line-height: 5.33vw;
    color: #FFFFFF;

    .loading-dots {
      position: relative;
      margin-left: 1.6vw;
      height: 1vw;
      width: 5.33vw;
      background: url("../../assets/invite/loading.gif") no-repeat center;
      background-size: 100% 100%;
    }
  }

  .success-page-button-second {
    position: relative;
    margin: 4.8vw auto 26vw auto;
    width: 84vw;
    height:12.8vw;
    border: 1px solid #7D33FF;
    display:flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 3.73vw;
    line-height: 5.33vw;
    color: #FFFFFF;
    z-index: 20;
  }

  .button-extra-style {
    margin: 4.8vw auto 8.8vw auto;
  }

  .policy-container {
    position: relative;
    margin: 0 auto 27vw auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    .checkbox-uncheck{
      position: relative;
      width: 3.73vw;
      height: 3.73vw;
      margin-right: 2.13vw;
      background: url("../../assets/redeemSuccess/uncheck-checkbox.png") no-repeat center;
      background-size: 100% 100%;
      z-index: 20;
    }

    .checkbox-checked{
      position: relative;
      width: 3.73vw;
      height: 3.73vw;
      margin-right: 2.13vw;
      background: url("../../assets/redeemSuccess/checked-checkbox.png") no-repeat center;
      background-size: 100% 100%;
      z-index: 20;
    }

    .policy-text{
      position: relative;
      font-weight: 400;
      font-size: 3.2vw;
      line-height: 3.73vw;
      color: rgba(255, 255, 255, 0.7);
    }

    .policy-button{
      position: relative;
      font-weight: 400;
      font-size: 3.2vw;
      line-height: 3.73vw;
      color: #DBCEB5;
      z-index: 20;
    }
  }

  .bottom-icon {
    position: absolute;
    bottom:10.67vw;
    left: 8.53vw;
    width: 3.47vw;
    height: 3.47vw;
    background: url("../../assets/common/le-icon.png") no-repeat center;
    background-size: 100% 100%;
  }

  .bottom-text {
    position: absolute;
    bottom: 11.47vw;
    right: 8vw;
    height: 7.36vw;
    width: 16.96vw;
    background: url("../../assets/redeemSuccess/bottom-text.png") no-repeat center;
    background-size: 100% 100%;
  }

  .bottom-light {
    position: absolute;
    bottom: 0;
    background: url("../../assets/common/bottom-light.png") no-repeat center;
    background-size: 100% 100%;
    mix-blend-mode: lighten;
    width: 100vw;
    height: 97.33vw;
  }

  .bottom-policy-button {
    position: absolute;
    bottom:10.5vw;
    left: 36.5vw;
    font-weight: 400;
    font-size: 2.67vw;
    line-height: 3.73vw;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.6;
  }
}