.invite-page {
  position: relative;
  top:0;
  width: 100vw;
  min-height:100vh;
  display: flex;
  flex-direction: column;
  background: url("../../assets/invite/invite-page-bg.png") no-repeat center;
  background-size: cover;

  .jidu-icon {
    position: absolute;
    top:9vw;
    left: 8.8vw;
    background: url("../../assets/invite/jidu-icon.png") no-repeat center;
    background-size: 100% 100%;
    width: 15vw;
    height: 3.8vw;
  }

  .lunar-subtitle {
    position: absolute;
    top: 14.5vw;
    left: 8.27vw;
    font-family: 'Jidu ROBOFont';
    -webkit-text-stroke: 0.5px #DBCEB5;
    -webkit-text-fill-color: transparent;
    font-weight: 400;
    font-size: 10.67vw;
    line-height: 13.87vw;
  }

  .page-main-title-first {
    position: relative;
    margin-left: 8vw;
    margin-top: 27vw;
    font-weight: 700;
    font-size: 8.53vw;
    line-height: 8.53vw;
    letter-spacing: 0.5vw;
    color: #DBCEB5;
  }

  .page-main-title-second {
    position: relative;
    margin-top: 2vw;
    margin-left: 8vw;
    margin-bottom: 4.27vw;
    font-weight: 400;
    font-size: 8.53vw;
    line-height: 8.53vw;
    letter-spacing: 0.4vw;
    color: #FFFFFF;
  }

  .nft-poster-back-container {
    position: relative;
    margin: 0 auto;
    background: url("../../assets/invite/nft-poster-back-container.png") no-repeat center;
    background-size: 100% 100%;
    width: 83.7vw;
    height: 83.7vw;
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

    .le-icon {
      position: absolute;
      top: -9vw;
      right: 0;
      width: 3.87vw;
      height: 3.87vw;
      background: url("../../assets/common/le-icon.png") no-repeat center;
      background-size: 100% 100%;
    }

    .nft-image {
      position: relative;
      width: 76.8vw;
      height: 76.8vw;
    }

    .nft-loading-dots {
      position: relative;
      background: url("../../assets/invite/loading.gif") no-repeat center;
      background-size: 100% 100%;
      height: 3vw;
      width: 15vw;
    }

    .digital-collection-text {
      position: absolute;
      right: 0;
      bottom: -4vw;
      background: url("../../assets/invite/digital-collection-text.png") no-repeat center;
      background-size: 100% 100%;
      height: 1.35vw;
      width: 29vw;
    }
  }

  .tips-text {
    position: relative;
    margin: 8.8vw auto 4.27vw auto;
    max-width: 81.87vw;
    font-weight: 400;
    font-size: 3.73vw;
    line-height: 5.6vw;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.8;
    display: inline-block;
    white-space:pre-wrap;
  }

  .collect-invite-rewards-button {
    position: relative;
    margin: 0 auto 4.8vw auto;
    width: 84vw;
    height: 12.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 3.73vw;
    line-height: 5.33vw;
    color: #FFFFFF;
    background: #7D33FF;
    z-index: 20;
  }

  .save-poster-button {
    position: relative;
    margin: 0 auto;
    width: 84vw;
    height: 12.8vw;
    border: 1px solid #7D33FF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 3.73vw;
    line-height: 5.33vw;
    color: #FFFFFF;
    z-index: 20;
  }

  .save-poster-button-purple {
    border:none;
    background: #7D33FF;
  }

  .loading-status {
    border:none;
    background: rgba(120, 120, 120, 0.5);
    font-weight: 400;
    font-size: 3.73vw;
    line-height: 5.33vw;
    color: #FFFFFF;

    .loading-dots {
      position: relative;
      margin-left: 1.6vw;
      height: 1vw;
      width: 5.33vw;
      background: url("../../assets/invite/loading.gif") no-repeat center;
      background-size: 100% 100%;
    }
  }

  .invite-subtitle {
    position: relative;
    margin-top: 9vw;
    margin-left: 7.76vw;
    font-weight: 400;
    font-size: 10.67vw;
    line-height: 10.67vw;
    font-family: 'Jidu ROBOFont';
    -webkit-text-stroke: 0.5px #DBCEB5;
    -webkit-text-fill-color: transparent;
  }

  .invite-record-title {
    position: relative;
    margin-top: 3vw;
    margin-left: 7.73vw;
    margin-bottom: 2.67vw;
    font-weight: 400;
    font-size: 4.27vw;
    line-height: 4.8vw;
    color: #FFFFFF;
  }

  .invite-record-subtitle {
    position: relative;
    margin-left: 7.73vw;
    font-weight: 400;
    font-size: 3.2vw;
    line-height: 3.2vw;
    color: #FFFFFF;
    opacity: 0.8;
  }

  .invite-record-info-container {
    position: relative;
    margin: 3vw auto;
    width: 84vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 20;

    .invitee-info-container:first-child {
      border:none
    }

    .invitee-info-container:last-child {
      margin-bottom:4vw;
    }

    .empty-sign-container {
      position: relative;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .empty-sign {
        position: relative;
        height:13.33vw;
        width: 9.87vw;
        background: url("../../assets/invite/empty-sign.png") no-repeat center;
        background-size: 100% 100%;
      }

      .no-record-text {
        position: relative;
        margin-top:5vw;
        margin-bottom: 6vw;
        font-weight: 400;
        font-size: 3.2vw;
        line-height: 4.8vw;
        color: rgba(255, 255, 255, 0.8);
      }
    }

  }

  .bottom-light {
    position: absolute;
    bottom: -1px;
    background: url("../../assets/common/bottom-light.png") no-repeat center;
    background-size: 100% 100%;
    mix-blend-mode: lighten;
    width: 100vw;
    height: 97.33vw;
    z-index: 10;
  }

  .bottom-block {
    position: absolute;
    bottom:-1px;
    width: 100vw;
    height:1.5px;
    background: #191a2b
  }
}