.invitee-info-container {
  position: relative;
  width: 100%;
  height: 14.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 0.5px solid rgba(219, 206, 181, 0.4);

  .invitee-avatar {
    position: relative;
    width: 6.93vw;
    height: 6.93vw;
    margin-right: 2.13vw;
    border-radius: 50%;
  }

  .invitee-name {
    position: relative;
    font-weight: 400;
    font-size: 3.73vw;
    line-height: 3.73vw;
    color: #FFFFFF;
    margin-right: 2vw;
  }

  .invitee-phone-num {
    position: relative;
    font-weight: 400;
    font-size: 3.73vw;
    line-height: 3.73vw;
    color: #FFFFFF;
    opacity: 0.4;
  }
}