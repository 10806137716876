.poster-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);
  user-select: none;
  -webkit-user-select: none;

  .poster-content {
    position: relative;
    min-width: 100vw;
    height: 100vh;
    display: inline-block;
    white-space:pre-wrap;
    overflow-y: scroll;

    .icon-back {
      position: absolute;
      top: 5.1vw;
      left: 6.4vw;
      background: url("../../assets/common/close-icon.png") no-repeat center;
      background-size: 100% 100%;
      width: 6.4vw;
      height: 6.4vw;
      z-index: 5;
    }

    .poster-img {
      position: relative;
      width: 88vw;
      margin-top: 11.5vw;
      margin-bottom: 3vw;
      margin-left:6vw;
      z-index: 9999;
      -webkit-touch-callout: default !important;
    }

    .bottom-tips-text {
      position: relative;
      margin-bottom: 20vw;
      width: 100vw;
      text-align: center;
      font-weight: 400;
      font-size: 3.73vw;
      line-height: 5.33vw;
      color: #FFFFFF;
    }
  }
}