.gain-chance-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(16px);

  .modal-content-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: inline-block;
    overflow-y: scroll;

    .game-icon {
      position: relative;
      margin: 65vw auto 4vw auto;
      background: url("../../assets/common/game-icon.png") no-repeat center;
      background-size: 100% 100%;
      width: 20.32vw;
      height: 15.35vw;
    }

    .game-info-text {
      position: relative;
      width: 100vw;
      text-align: center;
      font-weight: 500;
      font-size: 3.73vw;
      line-height: 5.33vw;
      color: #FFFFFF;
    }
  }

}