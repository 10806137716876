@import "../../App.scss";

.intro-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);

  .skip-button {
    position: absolute;
    top: 8vw;
    right: 0;
    justify-content: center;
    width: 14.13vw;
    height: 7.47vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #DBCEB5;
    font-size: calc(12 / 375 * 100vw);
    line-height: calc(12 / 375 * 100vw);
    vertical-align: middle;
  }
  .skip-button::before {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #dbceb5;
    border-radius: 71.73vw 0 0 71.73vw;
    content: "";
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    width: vw(106);
    height: vw(56);
  }

  .robbit-title {
    position: relative;
    margin-top: 17.07vw;
    margin-left: vw(30);
    width: vw(264);
    height: vw(30);
    background-image: url("../../assets/introModal/1171274797.png");
    background-size: 100% 100%;
  }

  .intro-main-title {
    position: relative;
    margin-left: vw(29);
    font-weight: 700;
    font-size: vw(18);
    line-height: vw(38);
    color: #dbceb5;
  }

  .text-outer-container {
    position: relative;
    margin: vw(8) auto vw(10) auto;
    background: url("../../assets/introModal/content-container.png") no-repeat
      center;
    background-size: 100% 100%;
    width: 84vw;
    height: 107.47vw;
    overflow-y: scroll;
    .text-content {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */
      text-align: justify;
      text-transform: uppercase;
      color: #ffffff;
      margin: vw(32) vw(20);
    }
  }

  .digital-collection-text {
    position: relative;
    left: vw(235);
    background: url("../../assets/invite/digital-collection-text.png") no-repeat center;
    background-size: 100% 100%;
    height: 1.35vw;
    width: 29vw;
  }

  .start-discover-button {
    position: relative;
    margin: vw(42) auto 19.73vw auto;
    width: 84vw;
    height: 12.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 3.73vw;
    line-height: 5.33vw;
    color: #ffffff;
    background: #7d33ff;
    z-index: 20;
  }
}
