.policy-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 999;
  background: url("../../assets/common/bg-short.png") no-repeat center;
  background-size: cover;

  .icon-close {
    position: absolute;
    top: 5.3vw;
    left: 6.4vw;
    background: url("../../assets/common/close-icon.png") no-repeat center;
    background-size: 100% 100%;
    width: 6.4vw;
    height: 6.4vw;
    z-index: 5;
  }

  .policy-title {
    position: relative;
    margin-top: 19vw;
    margin-left: 8vw;
    margin-bottom: 8vw;
    font-weight: 700;
    font-size: 5.33vw;
    line-height: 5.33vw;
    color: #DBCEB5;
  }

  .content-text {
    position: relative;
    margin: 0 auto;
    overflow-y: scroll;
    width: 84vw;
    height: calc(100vh - 40vw);
    font-weight: 400;
    font-size: 3.2vw;
    line-height: 5.86vw;
    color: #FFFFFF;
    display: inline-block;
    white-space:pre-wrap;
  }
}