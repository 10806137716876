@keyframes slideInDown {
  from {
    transform: translate3d(0, -1000%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-duration: 1s;
  animation-name: slideInDown;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 1000%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-duration: 1s;
  animation-name: slideInUp;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -1000%, 0);
  }
}

.slideOutUp {
  animation-duration: 1s;
  animation-name: slideOutUp;
}