.invite-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items:center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(16px);

  .modal-content-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: inline-block;
    overflow-y: scroll;

    .invite-title {
      position: relative;
      margin-top: 46vw;
      width: 100vw;
      text-align: center;
      font-weight: 400;
      font-size: 10.67vw;
      line-height: 10.67vw;
      font-family: 'Jidu ROBOFont';
      -webkit-text-stroke: 0.5px #DBCEB5;
      -webkit-text-fill-color: transparent;
    }
  
    .invite-text1 {
      position: relative;
      margin-top:2vw;
      width: 100vw;
      text-align: center;
      font-weight: 700;
      font-size: 6.4vw;
      line-height: 9.33vw;
      color: #DBCEB5;
    }
  
    .total-count-container {
      position: relative;
      margin-top: 4vw;
      margin-bottom:7.2vw;
      margin: 4vw auto 7.2vw auto;
      display: flex;
      flex-direction:row;
      align-items: flex-end;
      justify-content: center;
  
      .count-num {
        position: relative;
        margin-right: 2.33vw;
        font-weight: 400;
        font-size: 14.93vw;
        line-height: 14.93vw;
        color: #FFFFFF;
      }
  
      .people-count {
        position: relative;
        font-weight: 400;
        font-size: 4.27vw;
        line-height: 6.13vw;
        color: #FFFFFF;
      }
    }
  
    .invite-text2 {
      position: relative;
      width: 100vw;
      text-align: center;
      font-weight: 400;
      font-size: 4.27vw;
      line-height: 6.13vw;
      color: #DBCEB5;
    }
  
    .button-group {
      position: relative;
      margin: 13.6vw auto 40vw auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  
      .button-left{
        position: relative;
        margin-right: 2.93vw;
        width: 40.53vw;
        height: 12.8vw;
        border: 1px solid #7D33FF;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 3.73vw;
        line-height: 5.33vw;
        color: #FFFFFF;
      }
  
      .button-right {
        position: relative;
        width: 40.53vw;
        height: 12.8vw;
        background: #7D33FF;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 3.73vw;
        line-height: 5.33vw;
        color: #FFFFFF;
      }
    }
  }

}