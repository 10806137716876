$vw_base: 375;
@function vw($px) {
  @return calc($px / $vw_base) * 100vw;
}
.only-one {
  position: relative;
  padding: vw(50) 6.4vw vw(13);
  background-color: #02001a;
  background-image: url('./assets/dfe6578dd73051bdca6f1065eeea0201.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100vw;
  overflow-x: hidden;
  .group {
    padding: 0 2.13vw;
    .text {
      align-self: flex-start;
      width: vw(264);
      height: vw(30);
      background-image: url("./assets/1171274797.png");
      background-size: 100% 100%;
    }
    .text_10 {
      margin-top: vw(2);
      font-weight: 700;
      white-space: nowrap;
      font-size: vw(17.7);
      line-height: vw(38);
      color: #DBCEB5;
    }
    .image-wrapper {
      margin-top: 3.47vw;
      padding: vw(22) vw(15) vw(15);
      background-image: url('./assets/16612253321672681111.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .level_title {
        margin-bottom: vw(18);
        .level {
          font-size: vw(18);
          line-height: vw(21);
          vertical-align: middle;
          color: #DBCEB5;
          font-family: "Jidu Mono Pro";
        }
        .title {
          font-size: vw(16);
          line-height: vw(16);
          vertical-align: middle;
          color: #DBCEB5;
        }
      }
      .image_1 {
        width: 73.07vw;
        height: 73.07vw;
      }
    }
  }
  .digital_collection {
    display: flex;
    justify-content: flex-end;
    .image1 {
      position: relative;
      width: vw(110);
      height: vw(5);
      margin: vw(10) 0 0;
      right: vw(9);
    }
  }
  .equity_desc {
    margin: vw(22) 0 0;
    font-size: vw(14);
    line-height: vw(20);
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }
  .button {
    position: relative;
    margin: 6.67vw 1.6vw 0;
    padding: 4.8vw 0 4.53vw;
    background-color: #7d33ff;
    .text_1 {
      color: #ffffff;
      font-size: 3.73vw;
      line-height: 3.47vw;
    }
    z-index: 1;
  }
  .group_1 {
    margin-top: 5.87vw;
    padding: 0 1.6vw;
    .text-wrapper {
      flex: 1 1 40.53vw;
      padding: 4.8vw 0 4vw;
      height: 12.8vw;
      border: solid 0.27vw #824dfc;
      .text_2 {
        position: relative;
        color: #ffffff;
        font-size: 3.73vw;
        line-height: 3.47vw;
        z-index: 1;
      }
    }
    .view_1 {
      position: relative;
      margin-left: 2.67vw;
      z-index: 1;
    }
  }
  .group_2 {
    margin-top: 10.13vw;
    padding-left: 2.13vw;
    padding-right: 1.07vw;
    .text_5 {
      text-transform: uppercase;
    }
    .text_6 {
      align-self: flex-end;
      color: #dbceb5;
      font-size: 1.87vw;
      font-family: Jidu Mono Pro;
      line-height: 3.2vw;
    }
    .text-wrapper_1 {
      margin-top: 2.53vw;
      padding: 1.07vw 0 0.8vw;
      width: 3.47vw;
      height: 3.47vw;
      border: solid 0.13vw #dbceb5cc;
      .text_7 {
        color: #e0d6c2;
        font-size: 1.87vw;
        font-family: Jidu Mono;
        line-height: 1.33vw;
      }
    }
    .group_4 {
      width: 16.8vw;
      .text_8 {
        align-self: flex-end;
        color: #dbceb5;
        font-size: 1.87vw;
        font-family: Jidu Mono Pro;
        line-height: 3.2vw;
      }
      .text_9 {
        align-self: flex-end;
        color: #dbceb5;
        font-size: 1.87vw;
        font-family: Jidu Mono Pro;
        line-height: 3.2vw;
      }
    }
  }
  .notice {
    position: relative;
    margin: vw(15) 0 0;
    font-size: vw(10);
    line-height: vw(20);
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
  }
  .bottom-light {
    position: absolute;
    bottom: 0;
    left: 0;
    background: url("../../assets/common/bottom-light.png") no-repeat center;
    background-size: 100% 100%;
    mix-blend-mode: lighten;
    width: 100vw;
    height: 97.33vw;
  }
}

.pick-one {
  position: relative;
  padding: vw(70) 1.07vw 9.73vw 2.27vw;
  background-color: #02001a;
  background-image: url('./assets/dfe6578dd73051bdca6f1065eeea0201.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position-y: -27px;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100vh;
  .group_2 {
    padding: 0 4.93vw;
    .text {
      width: vw(310);
      height: vw(30);
      background-image: url("./assets/1171274799.png");
      background-size: 100% 100%;
    }
    .text_2 {
      color: #dbceb5;
      font-size: vw(22);
      font-weight: 700;
      line-height: vw(38);
      margin-top: vw(6);
    }
  }
  .group_3 {
    margin-top: vw(43.8);
    .text_3 {
      margin-left: 5.2vw;
      align-self: flex-start;
      color: #dbceb5;
      font-size: 2.67vw;
      font-family: Jidu ROBOFont;
      line-height: 1.87vw;
    }
    .group_4 {
      padding-bottom: 44vw;
      position: relative;
      .image_2 {
        margin-right: 6.13vw;
        width: 2.4vw;
        height: 2.27vw;
      }
      .section_2 {
        padding-bottom: vw(164.9);
        background-image: url('./assets/f1ca394bdc66b651935ae4374a8d423a.png');
        background-position: 0% 0%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        left: 5.2vw;
        right: 6.13vw;
        top: 0;
        .image_3 {
          flex-shrink: 0;
          width: 2.13vw;
          height: 2.4vw;
        }
        .equal-division {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          .equal-division-item {
            padding: 0 0 1.47vw;
            width: 40.93vw;
            .text_4 {
              align-self: center;
              color: #dbceb5;
              font-size: vw(12);
              font-family: Jidu Mono Pro;
              line-height: vw(16);
              margin: vw(9) 0 vw(4) vw(11);
            }
            .image-wrapper {
              width: vw(136);
              height: vw(136);
              margin: 0 0 vw(8) vw(11);
              padding: vw(5);
              position: relative;
              .image_4 {
                width: vw(126);
                height: vw(126);
              }
            }
            .image_5 {
              align-self: flex-start;
              width: 2.4vw;
              height: 2.13vw;
            }
          }
          .equal-division-item_1 {
            margin-left: 5.87vw;
            padding-bottom: 1.33vw;
            .group_5 {
              width: 36vw;
              height: 46.13vw;
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              .text_5 {
                color: #dbceb5;
                font-size: vw(12);
                font-family: Jidu Mono Pro;
                line-height: vw(16);
                position: relative;
                margin: 2.4vw;
              }
              .section_12 {
                width: vw(136);
                height: vw(136);
                position: relative;
                left: vw(-1);
                top: vw(-5);
                padding: vw(5);
                img {
                  width: vw(126);
                  height: vw(126);
                }
              }
            }
            .image_6 {
              margin-top: 43.73vw;
              width: 2.27vw;
              height: 2.4vw;
            }
          }
        }
      }
    }
    .group_6 {
      height: 11.87vw;
      position: relative;
      .image_7 {
        width: 12vw;
        height: 9.47vw;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      .image_8 {
        opacity: 0.3;
        width: 90.4vw;
        height: 9.6vw;
        position: absolute;
        left: 3.07vw;
        right: 3.2vw;
        top: 0;
      }
      .image_9 {
        width: 11.73vw;
        height: 9.47vw;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .text_6 {
        color: #fbebd0;
        font-size: 1.47vw;
        font-family: Jidu Mono;
        line-height: 1.07vw;
        text-transform: uppercase;
        position: absolute;
        right: 12.13vw;
        top: 1.73vw;
      }
      .section_14 {
        background-color: #fbebd0;
        width: 0.4vw;
        height: 0.4vw;
        position: absolute;
        right: 33.07vw;
        top: 2.27vw;
      }
      .image_10 {
        filter: drop-shadow(0px 0px 0.53vw #dbceb5, 0px 0.27vw 0.53vw #dbceb5);
        width: 26.67vw;
        height: 3.47vw;
        position: absolute;
        left: 13.73vw;
        top: 3.2vw;
      }
    }
  }
  .desc_group {
    margin: vw(20) 0 0;
    position: relative;
    left: 5.2vw;
    .desc {
      width: vw(141);
      margin: 0 vw(11);
      font-size: vw(10);
      color: #ffffff;
      text-align: justify;
    }
    .desc::first-line {
      text-align: center;
    }
  }
  .button {
    position: relative;
    margin: vw(55) 6.93vw 0 5.73vw;
    padding: 4.8vw 0 4.53vw;
    background-color: #7d33ff;
    .text_7 {
      color: #ffffff;
      font-size: 3.73vw;
      line-height: 3.47vw;
    }
    z-index: 1;
  }
  .group_7 {
    margin-top: vw(120);
    padding: 0 6.27vw;
    .text_8 {
      align-self: flex-end;
      color: #dbceb5;
      font-size: 1.87vw;
      font-family: Jidu Mono Pro;
      line-height: 3.2vw;
    }
    .text-wrapper {
      margin-top: 2.53vw;
      padding: 1.07vw 0 0.8vw;
      width: 3.47vw;
      height: 3.47vw;
      border: solid 0.13vw #dbceb5cc;
      .text_9 {
        color: #e0d6c2;
        font-size: 1.87vw;
        font-family: Jidu Mono;
        line-height: 1.33vw;
      }
    }
    .group_9 {
      width: 16.8vw;
      .text_10 {
        align-self: flex-end;
        color: #dbceb5;
        font-size: 1.87vw;
        font-family: Jidu Mono Pro;
        line-height: 3.2vw;
      }
      .text_11 {
        align-self: flex-end;
        color: #dbceb5;
        font-size: 1.87vw;
        font-family: Jidu Mono Pro;
        line-height: 3.2vw;
      }
    }
  }
  .text {
    text-transform: uppercase;
  }
  .bottom-light {
    position: absolute;
    bottom: 0;
    left: 0;
    background: url("../../assets/common/bottom-light.png") no-repeat center;
    background-size: 100% 100%;
    mix-blend-mode: lighten;
    width: 100vw;
    height: 97.33vw;
  }
}