$vw_base: 375;
@function vw($px) {
  @return ($px / $vw_base) * 100vw;
}

.result-page {
  .image.selected::before {
    content: '';
    width: vw(140);
    height: vw(145);
    position: absolute;
    top: vw(-2);
    left: vw(-2);
    background: url("./assets/border.png");
    background-size: 100% 100%;
  }
  .unselected {
    filter: brightness(0.4);
  }
  .unselected.text_4, .unselected.text_5 {
    color: #ffffff !important; 
  }
  .button.disabled {
    background: rgba(120, 120, 120, 0.5);
  }
}