.adm-toast-mask .adm-toast-main {
    //display: inline-block;
    //position: relative;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    //width: auto;
    //min-width: 96px;
    //max-width: 200px;
    //max-height: 70%;
    //overflow: auto;
    //color: white;
    //word-break: break-all;
    //border-radius: 8px;
    //pointer-events: all;
    //font-size: var(--adm-font-size-7);
    //line-height: 1.5;
    //box-sizing: border-box;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 6px;

    color: #050505;
    font-size: 14px;
    line-height: 20px;
}